import {Link, Outlet} from 'react-router-dom'
import React, {FC, useCallback, useEffect, useState} from 'react'
import logo from '../logo.svg'
import client from '../remote'

const MainLayout: FC = () => {
  const [ip, setIp] = useState<string>('')
  const init = useCallback(async () => {
    setIp((await client.get('/get-ip') as any).ip)
  }, [])
  useEffect(() => {
    init().then()
  }, [init])
  return (
  <div>
    <header>
      <Link to="/home">Home</Link>&nbsp;
      <Link to="/test">Test</Link>
    </header>
    <span className="messio" style={{marginLeft: '2em'}}>MESSIO</span>
    <img src={logo} className="App-logo" alt="logo" />
    <p>{ip}</p>
    <div>
      <Outlet/>
    </div>
  </div>
  )
}

export default MainLayout