
const client: any = {
  rpc: async function (endPoint: string, method: string = 'GET', contentType: string = '', body: any | null = null ): Promise<any> {
    // console.log(`Calling endpoint: ${endPoint}`)
    const options: any = {
      method,
      headers: {
        'Accept': 'application/json',
        credentials: 'include',
      },
    }
    const token = sessionStorage.getItem('token')
    if (token && token.length > 0){
      options.headers['Authorization'] = `Bearer ${token}`
    }
    if (body){
      if (contentType){
        options.headers['Content-Type'] = contentType
      }
      options.body = body;
    }
    // console.debug(JSON.stringify(options))
    const res = await fetch(`${process.env.REACT_APP_REMOTE_URL}${endPoint}`, options)
    if (!res.ok){
      throw Error(`Http error: ${res.status} ${res.statusText}`)
    }
    const json = await res.json()
    if (typeof json === 'object' && 'error' in json) {
      const message = json.error
      throw Error(message)
    }
    return json;
  },
  get: function<T>(endPoint: string): Promise<T> {
    return this.rpc(endPoint)
  } ,
  post: function(endPoint: string, data: any): Promise<any> {
    return this.rpc(endPoint, 'POST', 'application/json', JSON.stringify(data))
  },
  postForm: function(endPoint: string, form: FormData): Promise<any> {
    return this.rpc(endPoint, 'POST', undefined, form)
  },  // contentType: undefined so boundary is correctly set
  put: function(endPoint: string, data: any): Promise<any> {
    return this.rpc(endPoint, 'PUT', 'application/json', JSON.stringify(data))
  },
  delete: function(endPoint: string): Promise<any> {
    return this.rpc(endPoint, 'DELETE')
  }
}

export default client

